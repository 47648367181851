import React from 'react';
import { Outlet, Link } from 'react-router-dom';
//image
import Image1 from '../assets/portfolio-1.png';
import Image2 from '../assets/portfolio-2.png';
import Image3 from '../assets/portfolio-3.png';
import Image4 from '../assets/portfolio-4.png';

const Work = () => {
  return(
    <section className='section p-[20px] lg:p-32 mb-[200px]' id='work'>
      <div className='container mx-auto'>
      <div className='text-center mb-8'>
          <h2 className='h2 text-accent font-extrabold leading-[1px]'>My Works</h2>
          <span className='text-slate-400 '>What I Do?</span>
      </div> 
       <div className='flex flex-col lg:flex-row gap-x-10'>
        <div>
          <ul className='mb-8'>
            <li className='text-accent text-[25px]'>My Work List -</li><br />
            <li className='hover:text-accent'><a href='https://github.com/samima-789/Portfolio-1' className='text-[22px] lg:text-[18px]'>(1) Personal Portfolio(HTML, CSS)</a> </li><br />
            <li className='hover:text-accent'><a href='https://github.com/samima-789/ZT-IT-Company' className='text-[22px] lg:text-[18px]'>(2) ZT IT Company(JavaScript)</a> </li><br />      
            <li className='hover:text-accent'><a href='https://github.com/samima-789/IT-Institute' className='text-[22px] lg:text-[18px]'>(3) IT Institute(Tailwind CSS)</a> </li><br />          
            <li className='hover:text-accent'><a href='https://github.com/samima-789/Coffee-Shop' className='text-[22px] lg:text-[18px]'>(4) Coffee Shop(BOOtstrap 5)</a> </li>         
          </ul>
          <button className='btn btn-sm w-full lg:w-[200px]'><Link to={"/WorkList"}>See All Project</Link></button>
          <Outlet />
        </div>
        <div className='flex-1 flex flex-col gap-y-12 mt-10 lg:mt-0'> 
          
          <a href='https://github.com/samima-789/Portfolio-1' target='_blank'>
          <div className='group relative overflow-hidden w-[300px] lg:w-full border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image1} alt='' />
            <div className='absolute -bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>Personal Portfolio</span>
              </div>
            <div className='absolute -bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>HTML, CSS</span>
            </div>
          </div>
          </a>
        <a href='https://github.com/samima-789/Coffee-Shop' target='_blank'>
          <div className='group relative overflow-hidden w-[300px] lg:w-full lg-w border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image4} alt='' />
            <div className='absolute -bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>Coffee Shop</span>
              </div>
            <div className='absolute -bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>BootStrap 5</span>
            </div>
          </div>
        </a>  

          
        </div>
        

        <div className='flex-1 flex flex-col mt-10 lg:mt-0 gap-y-10'>
        <a href='https://github.com/samima-789/IT-Institute' target='_blank'>
        <div className='group relative overflow-hidden w-[300px] lg:w-full border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image2} alt='' />
            <div className='absolute -bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>IT Institute</span>
              </div>
            <div className='absolute -bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>Tailind CSS</span>
            </div>
          </div>
        </a>
           <a href='https://github.com/samima-789/ZT-IT-Company' target='_blank'>
           <div className='group relative overflow-hidden w-[300px] lg:w-full border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image3} alt='' />
            <div className='absolute -bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>ZT IT Company</span>
              </div>
            <div className='absolute -bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>Javascript</span>
            </div>
          </div>
          </a>
        </div>
       </div>
      </div>
    </section>
  );
};

export default Work;






