import React from 'react';
//
import { FaUserGraduate } from "react-icons/fa";
 const Education = () =>{
    return(
        <section className='section p-[20px] lg:p-32 mb-[180px]' id='education'>
            <div className='container mx-auto'>
                    <div className='text-center mb-14'>
                      <h2 className='h2 text-accent font-extrabold leading-[1px]'>My Education</h2>
                      <span className='text-slate-400'>Where My Learning?</span>
                </div>    
                <div className='box-container flex flex-col lg:flex-row gap-10 justify-between items-center'>
                  <div className='box w-full pl-10 border-l-4 relative'>
                  <FaUserGraduate className='absolute top-[-15px] left-[-15px] w-7 h-7 rounded-full leading-5 text-center bg-accent p-1' />
                  <span className='text-slate-400 text-[20px] lg:text-[16px]'>2019 to 2024</span>
                  <h3 className='text-accent text-[22px] lg:text-[20px]'>Daffodil International University</h3>
                  <p className='text-[20px] lg:text-[16px]'>B.Sc in Software Engineering</p>
                  </div>

                  <div className='box w-full pl-10 border-l-4 relative'>
                  <FaUserGraduate className='absolute top-[-15px] left-[-15px] w-7 h-7 rounded-full leading-5 text-center bg-accent p-1' />
                  <span className='text-slate-400 text-[20px] lg:text-[16px]'>2015 to 2017</span>
                  <h3 className='text-accent text-[22px] lg:text-[20px]'>Govt. Shah Sultan Collage, Bogura</h3>
                  <p className='text-[20px] lg:text-[16px]'>H.S.C</p>
                  </div>

                  <div className='box w-full pl-10 border-l-4 relative'>
                  <FaUserGraduate className='absolute top-[-15px] left-[-15px] w-7 h-7 rounded-full leading-5 text-center bg-accent p-1' />
                  <span className='text-slate-400 text-[20px] lg:text-[16px]'>2012 to 2015</span>
                  <h3 className='text-accent text-[22px] lg:text-[20px]'>Police Lines High School, Bogura</h3>
                  <p className='text-[20px] lg:text-[16px]'>S.S.C</p>
                  </div>
                </div>
            </div>
        </section>
    );
 };

 export default Education;