import React from 'react';
//icons
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

//countUp
import CountUp from 'react-countup';
//intersection observer
import {InView, useInView} from 'react-intersection-observer';
import { Link } from 'react-scroll';

const About = () => {
  
  return (
   <section className='section p-[20px] lg:p-32 mb-[180px]' id='about'>
    <div className='container mx-auto'>
    <div className='flex flex-col lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-10'>

      <div className='flex-1 bg-about bg-contain bg-no-repeat h-[400px] mix-blend-lighten bg-top'>
      </div>

      <div className='w-full mt-20 lg:flex-1'>
        <div className='text-center lg:text-left mb-6'>
        <h2 className='h2 text-accent font-extrabold leading-[1px]'>About Me</h2>
          <span className='text-slate-400 '>Frontend Developer</span>
      </div> 

        <p
        className='mb-4 lg:w-full text-center lg:text-left lg:text-[16px] lg:leading-6'>
        I'm a frontend developer passionate about crafting seamless, user-friendly web experiences. With expertise in HTML, CSS, JavaScript, Bootstrap, jQuery, Tailwind CSS and modern frameworks like React.
        </p>

        <div
        className='flex justify-center  lg:justify-start items-center gap-x-6 lg:gap-x-10 text-center lg:text-left mb-10'>
          <div>
            <div className='text-[25px] font-tertiary text-gradient mb-0'>{
              InView ?
              <CountUp start={0} end={2} duration={3} /> : null}
              +
            </div>
            <div className='font-primary text-[10px] leading-[12px]  tracking-[2px]'>
              Years Of <br />
              Experience
            </div>
          </div>

          <div>
            <div className='text-[25px] font-tertiary text-gradient mb-0'>{
              InView ?
              <CountUp start={0} end={15} duration={3} /> : null}
              +
            </div>
            <div className='font-primary text-[10px] leading-[12px] tracking-[2px]'>
              Projects <br />
              Completed
            </div>
          </div>
          </div>

          <ul className='flex flex-row justify-center  lg:justify-start items-center gap-x-4 lg:gap-x-10 mb-8'>
              <li><a href='https://www.facebook.com/samimaaktertithy789' target='_blank' className='icon text-[28px]'><FaFacebookSquare/></a></li>
              <li><a href='https://www.instagram.com/tithysamima/' target='_blank' className='icon text-[28px]'><FaInstagramSquare /></a></li>
              <li><a href='https://wa.me/01630952656' target='_blank' className='icon text-[28px]'><FaWhatsappSquare /></a></li>
              <li><a href='https://www.youtube.com/' target='_blank' className='icon text-[28px]'><FaYoutube /></a></li>
            </ul>
        <div>
          <button
          className='btn btn-sm w-full lg:w-[200px]'><Link to='contact'>Contact Me</Link>
          </button>
        </div>
      </div>
    </div>
    </div>
    </section>
  );
};

export default About;
