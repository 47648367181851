import React from 'react';
//import icons
import { BiHomeAlt } from 'react-icons/bi';
//image
import Image1 from '../assets/portfolio-1.png';
import Image2 from '../assets/portfolio-2.png';
import Image3 from '../assets/portfolio-3.png';
import Image4 from '../assets/portfolio-4.png';
import Image5 from '../assets/Portfolio-5.png';
import Image6 from '../assets/Portfolio-6.png';
//Links
import { Outlet, Link } from 'react-router-dom';

const WorkList = () => {
  return(
    <section className='  p-20' id='workList'>
      <div className='container mx-auto'>
        <nav className='fixed left-0 bottom-2 lg:bottom-8 w-full overflow-hidden z-50'>
        <div className='z-40 bg-black/20 h-[60px] backdrop-blur-2xl rounded-full max-w-[60px] mx-auto px-5 flex justify-between items-center text-2xl text-white/50'>
        <Link to={"/"} className='cursor-pointer w-[40px] h-[40px] flex items-center justify-center'>
           <BiHomeAlt />
        </Link>
        </div>
        </nav>
      <div className='text-center mb-8'>
          <h2 className='h2 text-accent font-extrabold leading-[1px]'>My Works</h2>
          <span className='text-slate-400 '>What I Do?</span>
      </div> 
       <div className='flex flex-col lg:flex-row gap-x-10'>
        
        <div className='flex-1 flex flex-col gap-y-10 mb-10 lg:mb-0'>  
          <a href='https://github.com/samima-789/Portfolio-1' target='_blank'>
          <div className='group relative overflow-hidden  w-[250px] lg:w-full border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image1} alt='' />
            <div className='absolute -bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>Personal Portfolio</span>
              </div>
            <div className='absolute -bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>HTML, CSS</span>
            </div>
          </div>
          </a>
        <a href='https://github.com/samima-789/Coffee-Shop' target='_blank'>
          <div className='group relative overflow-hidden  w-[250px] lg:w-full border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image4} alt='' />
            <div className='absolute -bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>Coffee Shop</span>
              </div>
            <div className='absolute -bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>BootStrap 5</span>
            </div>
          </div>
        </a>  

        <a href='https://github.com/samima-789/Restaurant-website-1' target='_blank'>
          <div className='group relative overflow-hidden  w-[250px] lg:w-full border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image6} alt='' />
            <div className='absolute -bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>Reastora Restaurant</span>
              </div>
            <div className='absolute -bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>HTML, CSS</span>
            </div>
          </div>
        </a>  
        </div>
        

        <div className='flex-1 flex flex-col gap-y-10'>
        <a href='https://github.com/samima-789/IT-Institute' target='_blank'>
        <div className='group relative overflow-hidden bo w-[250px] lg:w-full border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image2} alt='' />
            <div className='absolute bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>IT Institute</span>
              </div>
            <div className='absolute bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>TailWind CSS</span>
            </div>
          </div>
        </a>
           <a href='https://github.com/samima-789/ZT-IT-Company' target='_blank'>
           <div className='group relative overflow-hidden w-[250px] lg:w-full  border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image3} alt='' />
            <div className='absolute bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>ZT IT Company</span>
              </div>
            <div className='absolute bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>Javascript</span>
            </div>
          </div>
          </a>

          <a href='#' target='_blank'>
          <div className='group relative overflow-hidden  w-[250px] lg:w-full border-2 border-white/50 rounded-xl'>
            <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
            <img className='group-hover:scale-125 transition-all duration-500' src={Image5} alt='' />
            <div className='absolute bottom-full left-12 group-hover:bottom-10 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
              <span className='text-gradient text-[10px] lg:text-[20px]'>Personal Portfolio</span>
              </div>
            <div className='absolute -bottom-full left-12 group-hover:bottom-5 lg:group-hover:bottom-14 transition-all duration-700 z-50'>
              <span className='text-[16px] lg:text-3xl text-white'>React</span>
            </div>
          </div>
        </a>  
        </div>
       </div>
      </div>
    </section>
  );
};

export default WorkList;
