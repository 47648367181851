import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import WorkList from './components/WorkList';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
  <BrowserRouter>
  <Routes> 
    <Route path='/' element={<App />}></Route>
    <Route path="/WorkList" element={<WorkList />}></Route>
  </Routes>
  </BrowserRouter>
  </div>
);
