import React from 'react';
const Skills = () => {
    return(
        <section className='section p-[20px] lg:p-32 mb-[100px]' id='skills'>
            <div className='container mx-auto'>
                <div className='text-center mb-8'>
                    <div className='text-center mb-8'>
                      <h2 className='h2 text-accent font-extrabold leading-[1px]'>My Skills</h2>
                      <span className='text-slate-400 '>What skills I have?</span>
                    </div> 
                </div>
                
                <div className='bar-section flex justify-center items-center'>
                    <div className='container1 w-[80%] lg:w-full'>
                      <div className='radial-bars mt-[10px] lg:mt-0 flex flex-col lg:flex-row lg:gap-x-[10px] gap-y-[100px] lg:gap-y-[10px] justify-between items-center '>
                          <div className='radial-bar w-[20%] h-[150px] lg:w-[20%] lg:h-[170px] lg:mb-[10px] relative'>
                            <svg x="0px" y="0px" viewBox='0 0 200 200'>
                                <circle className='progress-bar' cx="100" cy="100" r="80"></circle>
                                <circle className='path path-1' cx="100" cy="100" r="80"></circle>
                            </svg>
                            <div className='percentage'>98%</div>
                            <div className='text'>HTML</div>
                          </div>

                          <div className='radial-bar w-[20%] h-[170px] mb-[10px] relative'>
                            <svg x="0px" y="0px" viewBox='0 0 200 200'>
                                <circle className='progress-bar' cx="100" cy="100" r="80"></circle>
                                <circle className='path path-2' cx="100" cy="100" r="80"></circle>
                            </svg>
                            <div className='percentage'>95%</div>
                            <div className='text'>CSS</div>
                          </div>

                          <div className='radial-bar w-[20%] h-[170px] mb-[10px] relative'>
                            <svg x="0px" y="0px" viewBox='0 0 200 200'>
                                <circle className='progress-bar' cx="100" cy="100" r="80"></circle>
                                <circle className='path path-3' cx="100" cy="100" r="80"></circle>
                            </svg>
                            <div className='percentage'>85%</div>
                            <div className='text'>BOOTSTRAP</div>
                          </div>

                          <div className='radial-bar w-[20%] h-[170px] mb-[10px] relative'>
                            <svg x="0px" y="0px" viewBox='0 0 200 200'>
                                <circle className='progress-bar' cx="100" cy="100" r="80"></circle>
                                <circle className='path path-4' cx="100" cy="100" r="80"></circle>
                            </svg>
                            <div className='percentage'>50%</div>
                            <div className='text'>JAVASCRIPT</div>
                          </div>
                          <div className='radial-bar w-[20%] h-[170px] mb-[10px] relative'>
                            <svg x="0px" y="0px" viewBox='0 0 200 200'>
                                <circle className='progress-bar' cx="100" cy="100" r="80"></circle>
                                <circle className='path path-5' cx="100" cy="100" r="80"></circle>
                            </svg>
                            <div className='percentage'>90%</div>
                            <div className='text'>Tailwind CSS</div>
                          </div>

                          <div className='radial-bar w-[20%] h-[170px] mb-[10px] relative'>
                            <svg x="0px" y="0px" viewBox='0 0 200 200'>
                                <circle className='progress-bar' cx="100" cy="100" r="80"></circle>
                                <circle className='path path-6' cx="100" cy="100" r="80"></circle>
                            </svg>
                            <div className='percentage'>85%</div>
                            <div className='text'>jQuery</div>
                          </div>

                          <div className='radial-bar w-[20%] h-[170px] mb-[10px] relative'>
                            <svg x="0px" y="0px" viewBox='0 0 200 200'>
                                <circle className='progress-bar' cx="100" cy="100" r="80"></circle>
                                <circle className='path path-7' cx="100" cy="100" r="80"></circle>
                            </svg>
                            <div className='percentage'>50%</div>
                            <div className='text'>React</div>
                          </div>
                          
                      </div>
                    </div>
                    
                </div>
            </div>    
        </section>
    );
};

export default Skills;