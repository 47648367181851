import React from 'react';
//icons
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
//Image
import Image from '../assets/about-me.png';

const Contact = () => {
  return(
     <section className='section p-32' id='contact'>
      <div className='container mx-auto'>

      <div className='text-center mb-8'>
          <h2 className='h2 text-accent font-extrabold leading-[1px]'>Contact</h2>
          <span className='h3'>Let's Talk</span>
      </div> 
       <div className='flex flex-col lg:flex-row items-center'>
        <div className='flex-1 justify-start items-center'>
          <div className='relative'>
            <div className='flex flex-col gap-y-10 '>
              <div className='w-[350px] lg:w-96 h-28 border p-3 rounded-2xl text-center relative'>
                 <FaLocationDot className='m-0 m-auto '/>
                 <h4 className='font-semibold text-[20px] '>Address</h4>
                 <p className='text-[14px]'>Mirpur-12 DOHS, Dhaka</p>
              </div>
              <div className='w-[350px] lg:w-96 h-28 border p-3 rounded-2xl text-center relative'>
                 <FaPhone className='m-0 m-auto '/>
                 <h4 className='font-semibold text-[20px] '>Phone</h4>
                 <p className='text-[14px]'>01630952656</p>
              </div>
              <div className='w-[350px] lg:w-96 h-28 border p-3 rounded-2xl text-center relative'>
                 <MdAlternateEmail className='m-0 m-auto '/>
                 <h4 className='font-semibold text-[20px] '>Email</h4>
                 <p className='text-[14px]'>tithysamima789@gmail.com</p>
              </div>
            </div>
          </div>  
        </div>




        <form className='flex w-[350px] lg:w-[500px] border rounded-2xl flex flex-col lg:mt-0 mt-5  pb-10 p-4 items-center'>
        <input className='bg-transparent border-b py-2 outline-none w-full placeholder:text-slate-400 focus:border-accent transition-all' type='text' placeholder='Your name' />

        <input className='bg-transparent border-b py-2 outline-none w-full placeholder:text-slate-400 focus:border-accent transition-all' type='text' placeholder='Your email' />

        <textarea className='bg-transparent border-b py-12 outline-none w-full placeholder:text-slate-400 focus:border-accent transition-all resize-none mb-8' type='text' placeholder='Your message'></textarea>

        <button className='btn btn-sm w-full lg:w-[200px] mb-8'>Send Message</button>
        </form>
       </div>
      </div>
     </section>
  );
};

export default Contact;
