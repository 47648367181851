import React from 'react';
//images
import Image from '../assets/me.png';
import CV from '../assets/CV.pdf';
//icons
import { FaGithub, FaLinkedin, FaDribbble } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';

const Banner = () => {
  return(
   <section className=' w-full min-h-[85vh] p-10 lg:min-h-[78vh] flex justify-center items-center lg:justify-center lg:items-center ' id='home'>

    <div className='container mx-auto'>
     <div className='flex flex-col justify-center items-center '>

      <div className='relative text-center font-secondary'>
        <span className='text-accent font-tertiary text-[25px] lg:text-[20px]'>Hi! I'm</span>
        <h1 className='text-[45px] font-bold leading-[48px] lg:text-[45px] lg:leading-[78px]'>Samima Akter Tithy</h1>

        <div className='mb-6 text-[36px] lg:text-[30px] font-secondary font-semibold uppercase leading-[15px]'>
          <span className='text-white/50 mr-3 text-[23px] lg:text-[18px] leading-[40px] lg:leading-10 '>Software Engineer</span>
        </div>
      <div className='flex  gap-x-5 justify-center items-center mb-8 max-auto'>
        <a href={CV} download="CV.pdf" className='text-gradient btn-link'>Download CV</a>
      </div>

      <div className='absolute top-48 left-[-80px] p-10'>
        <a href='https://github.com/samima-789'>
          <FaGithub className='text-[30px] lg:text-[25px]' />
        </a><br />
        <a href='https://www.linkedin.com/in/samima-akter-tithy-1a6780217/'>
          <FaLinkedin className='text-[30px] lg:text-[25px]' />
        </a><br />
        <a href='#'>
          <FaDribbble className='text-[30px] lg:text-[25px]' />
        </a><br />
        <a href='mailto:tithysamima789@gmail.com'>
          <SiGmail className='text-[30px] lg:text-[25px]' />
        </a>
      </div>
      </div>

      <div className='lg:flex flex-1 max-w-[320px] lg:max-w-[482px] mx-auto flex justify-center items-center'>
        <img src={Image} alt='' className='w-[150px] lg:w-[150px]' />
      </div>

     </div>
    </div>
  </section>
  );
};

export default Banner;
